import * as _ from 'lodash'
import * as React from 'react'
import {ResizableProps} from '.'

export class Resizable extends React.Component<ResizableProps> {
  containerRef: HTMLElement | null = null
  containerWidth: number = 0
  containerHeight: number = 0
  resizeInProgress = false
  resizedWhileInProgress = false

  updateCurrentSize = () => {
    this.containerWidth = this.containerRef && this.containerRef.offsetWidth
    this.containerHeight = this.containerRef && this.containerRef.offsetHeight
  }

  resize() {
    this.updateCurrentSize()
    this.props.resizeFunc(this.containerWidth, this.containerHeight)
  }

  // TODO: use lodash for throttling
  throttledResize = () => {
    if (this.resizeInProgress) {
      this.resizedWhileInProgress = true
    } else {
      this.resizeInProgress = true
      this.resize()

      setTimeout(() => {
        this.resizeInProgress = false

        if (this.resizedWhileInProgress) {
          this.resizedWhileInProgress = false
          this.throttledResize()
        }
      }, 100)
    }
  }

  componentDidMount() {
    this.resize()

    if (!this.props.width) {
      window.addEventListener('resize', this.throttledResize)
    }
  }

  setContainerRef = element => {
    this.containerRef = element
    this.updateCurrentSize()
  }

  componentDidUpdate(prevProps: ResizableProps) {
    const trimmedProps = _.omit(this.props, 'resizeFunc', 'children')
    const trimmedPrevProps = _.omit(prevProps, 'resizeFunc', 'children')

    if (!_.isEqual(trimmedProps, trimmedPrevProps)) {
      this.resize()
    }
    // withTranslate/withNamespaces does async render, so didUpdate fires too soon
    setTimeout(this.resizeOnContainerHeightChanged, 1)
  }

  resizeOnContainerHeightChanged = () => {
    if (this.containerHeightChanged()) {
      this.resize()
    }
  }

  containerHeightChanged() {
    const height = this.containerRef && this.containerRef.offsetHeight

    if (height !== this.containerHeight) {
      this.containerHeight = height
      return true
    }

    return false
  }

  render() {
    return <div ref={this.setContainerRef}>{this.props.children}</div>
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResize)
  }
}
